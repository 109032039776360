'use client';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { proxied } from 'radash';
import CategoryContainer from './category-container';
import PopularGames from './popular-game-container';
import { ProviderEnum } from '~constants/menus';
import useIsMobile from '~libs/use-is-mobile';
import 'swiper/css';
import 'swiper/css/navigation';
import { useCasinos } from '~hooks/use-casinos';
import { useSportsbook } from '~hooks/use-sports-book';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';
import { useAllGames } from '~hooks/use-gaming';
import { DEFAULT_CURRENCY, ProviderTypeEnum } from '~constants/etc';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import SportProviderContainer from '~containers/home/sport-provider-container';
import ProductMobileViewContainer from '~containers/home/product-mobile-view-container';
import AllProviderContainer from '~containers/home/all-provier-container';

const Products: FC<{
  agentInfo: AgentInfoResponseInterface;
  region: string;
}> = ({ agentInfo, region }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const router = useRouter();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: casinos } = useCasinos({
    currency,
  });
  const { data: gaming } = useAllGames({
    currency,
  });
  const { data: sports } = useSportsbook({
    currency,
  });

  const allProviders = [
    ...(casinos?.records?.map((res) => ({
      ...res,
      category: ProviderTypeEnum.Casino,
    })) || []),
    ...(gaming?.records?.map((res) => ({
      ...res,
      category: ProviderTypeEnum.Gaming,
    })) || []),
    ...(sports?.records?.map((res) => ({
      ...res,
      category: ProviderTypeEnum.Sportsbook,
    })) || []),
  ];

  const providerData = useMemo(() => {
    return {
      all: {
        key: 'all',
        name: t('common:all'),
        icon: '',
        data: allProviders,
      },
      [ProviderEnum.CASINO]: {
        key: ProviderEnum.CASINO,
        name: t('common:menu.casino'),
        icon: '/images/svg/flags/th.svg',
        data:
          casinos?.records?.map((res) => ({
            ...res,
            category: ProviderTypeEnum.Casino,
          })) || [],
      },
      [ProviderEnum.GAMING]: {
        key: ProviderEnum.GAMING,
        name: t('common:menu.gaming'),
        icon: '/images/svg/flags/en.svg',
        data:
          gaming?.records?.map((res) => ({
            ...res,
            category: ProviderTypeEnum.Gaming,
          })) || [],
      },
      [ProviderEnum.SPORT]: {
        key: ProviderEnum.SPORT,
        name: t('common:menu.sportsbook'),
        icon: '/images/svg/flags/vi.svg',
        data:
          sports?.records?.map((res) => ({
            ...res,
            category: ProviderTypeEnum.Sportsbook,
          })) || [],
      },
    };
  }, [allProviders, casinos, gaming, sports]);

  // const handleOpenCasino = (game: ProviderCasinoResApiInterface): void => {
  //   if (!userInfo) {
  //     return dialog.content({
  //       children: (
  //         <ClientSideRender>
  //           <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
  //         </ClientSideRender>
  //       ),
  //     });
  //   }
  //   try {
  //     const currencyFiltered = game.currencies
  //       .filter((x) => x.includes(currency))
  //       .at(0);
  //     const queryString = objectToQueryString({
  //       category: ProviderTypeEnum.Casino,
  //       providerId: game.id,
  //       currency: currencyFiltered,
  //       walletId: walletInfo?.objectId,
  //     });
  //     router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
  //   } catch (e) {
  //     HttpErrorHandler(e);
  //   }
  // };

  // const handleOpenSportsbook = async (
  //   game: ProviderSportResApiInterface,
  // ): Promise<void> => {
  //   if (!userInfo) {
  //     return dialog.content({
  //       children: (
  //         <ClientSideRender>
  //           <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
  //         </ClientSideRender>
  //       ),
  //     });
  //   }
  //   try {
  //     const currencyFiltered = game.currencies
  //       .filter((x) => x.includes(currency))
  //       .at(0);
  //     window.open(
  //       await onOpenSportURL(game.id, currencyFiltered, walletInfo?.objectId),
  //       '_blank',
  //     );
  //   } catch (e) {
  //     HttpErrorHandler(e);
  //   }
  // };

  const actionOpenGame = proxied((prop: ProviderTypeEnum) => {
    switch (prop) {
      case ProviderTypeEnum.Casino:
        return (provider: any) => {
          // handleOpenCasino(provider);
          router.push(`${PageUrls.Casino}/${provider.id}`);
        };
      case ProviderTypeEnum.Gaming:
        return (provider: any) =>
          router.push(`${PageUrls.Gaming}/${provider.id}`);
      case ProviderTypeEnum.Sportsbook:
        return (provider: any) => {
          // handleOpenSportsbook(provider)
          router.push(`${PageUrls.Sportsbook}/${provider.id}`);
        };
      default:
        throw new Error('The category you are looking for cannot be found.');
    }
  });

  if (isMobile) {
    return (
      <ProductMobileViewContainer
        agentInfo={agentInfo}
        onSelectProvider={(provider) => {
          actionOpenGame[provider.category](provider);
        }}
        providerData={providerData}
        region={region}
      />
    );
  }

  return (
    <div className="mx-auto w-full max-w-[2000px]">
      <CategoryContainer />
      <SportProviderContainer
        onOpenProvider={(provider) => {
          router.push(`${PageUrls.Sportsbook}/${provider.id}`);
        }}
        sportProviders={sports}
      />
      <AllProviderContainer
        onSelectProvider={(provider) => {
          actionOpenGame[provider.category](provider);
        }}
        providers={allProviders}
      />
      <PopularGames />
    </div>
  );
};

export default Products;
