import { cn } from 'class-merge';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import UnauthorizedNavigation from '~components/layouts/navbar/unauthorized-navigation';
import Marquee from '~components/marquee';
import ProviderCard from '~components/card/provider-card';
import useAuth from '~hooks/use-auth';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';
import type { ProviderEnum } from '~constants/menus';
import {
  NEXT_PUBLIC_S3_BUCKET_ASSETS_URL,
  S3_BUCKET_IMAGES,
} from '~constants/etc';
import { ClientSideRender } from '~components/layouts/client-side-render';

const ProductMobileViewContainer: FC<{
  agentInfo: AgentInfoResponseInterface;
  region: string;
  providerData: any;
  onSelectProvider: (provider: any) => void;
}> = ({ agentInfo, region, providerData, onSelectProvider }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const [selectProvider, setSelectProvider] = useState<ProviderEnum | string>(
    'all',
  );

  const providerInfo = useMemo(() => {
    return providerData[selectProvider].data.map((res) => ({
      ...res,
      hero: `${S3_BUCKET_IMAGES}/providers/hero-${res.id}.webp`,
      logo: `${NEXT_PUBLIC_S3_BUCKET_ASSETS_URL}/main/media/image/${res.id}/logo/${res.id}-logo.png`,
    }));
  }, [providerData, selectProvider]);

  const handleProviderClick = (providerKey: string): void => {
    setSelectProvider(providerKey);
  };

  return (
    <div className="relative flex flex-col space-y-4 px-4 pt-3">
      {!isLoggedIn ? (
        <div className="flex w-full items-center justify-center gap-2">
          <UnauthorizedNavigation agentInfo={agentInfo} region={region} />
        </div>
      ) : null}
      <div className={cn('w-full bg-black')}>
        <Marquee text={t('common:announce')} />
      </div>
      <div className="bg-light flex h-10 items-center justify-evenly rounded-3xl p-1 sm:h-14">
        {Object.keys(providerData).map((item) => {
          const data = providerData[item];
          return (
            <div
              aria-hidden
              className={cn(
                selectProvider === item && 'bg-primary',
                'flex h-full w-full items-center justify-center rounded-3xl p-1.5',
              )}
              key={item}
              onClick={() => {
                handleProviderClick(item);
              }}
              role="button"
            >
              <span className="text-sm capitalize sm:text-base">
                {data.name}
              </span>
            </div>
          );
        })}
      </div>
      <div className="mt-8 grid grid-cols-3 gap-4 sm:grid-cols-5">
        <ClientSideRender>
          {providerInfo ? (
            providerInfo?.map((item, i) => (
              <ProviderCard
                image={item.hero}
                key={i}
                logo={item.logo}
                onOpenGame={() => {
                  onSelectProvider(item);
                }}
                title={item.title}
                type={item.meta.category}
              />
            ))
          ) : (
            <div className="col-span-3 text-center">No Data</div>
          )}
        </ClientSideRender>
      </div>
    </div>
  );
};

export default ProductMobileViewContainer;
