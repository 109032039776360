import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useMemo } from 'react';
import { cn } from 'class-merge';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import type { ProviderSportResApiInterface } from '../../types/sport';
import type { PaginationResponseInterface } from '../../types/etc';
import {
  NEXT_PUBLIC_S3_BUCKET_ASSETS_URL,
  S3_BUCKET_IMAGES,
} from '~constants/etc';
import { Button } from '~components/button';
import { ButtonColors, SizeTypes } from '~constants/components';

const SportProviderContainer: FC<{
  sportProviders:
    | PaginationResponseInterface<ProviderSportResApiInterface[]>
    | undefined;
  onOpenProvider: (provider: any) => void;
}> = ({ sportProviders, onOpenProvider }) => {
  const { t } = useTranslation();

  const sportProviderMapped = useMemo(() => {
    if (!sportProviders) {
      return [];
    }
    return sportProviders?.records?.map((provider) => {
      return {
        ...provider,
        logo: `${NEXT_PUBLIC_S3_BUCKET_ASSETS_URL}/main/media/image/${provider.id}/logo/${provider.id}-horizontal-logo.png`,
        hero: `${S3_BUCKET_IMAGES}/hero-${provider.id}-recommend.webp`,
        background: `${S3_BUCKET_IMAGES}/bg-${provider.id}-recommend.webp`,
      };
    });
  }, [sportProviders]);

  if (!sportProviderMapped.length) return null;

  return (
    <div
      className={cn(
        'flex h-[400px] w-full flex-col justify-center bg-cover bg-center bg-no-repeat px-10 xl:px-[100px]',
      )}
      style={{
        backgroundImage: `url(${S3_BUCKET_IMAGES}/sport-bg.webp)`,
      }}
    >
      <div className="relative mb-4 capitalize">
        <h2 className=" text-3xl font-bold text-white">
          {t('menu.sportsbook')}
        </h2>
        <div className="bg-primary absolute -bottom-1 h-0.5 w-10" />
      </div>
      <div>
        <Swiper
          breakpoints={{
            1024: { slidesPerView: 3.1 },
            640: { slidesPerView: 2.1 },
            0: { slidesPerView: 1.1 },
          }}
          modules={[Navigation]}
          slidesPerView={3.1}
          spaceBetween={24}
        >
          {sportProviderMapped.map((provider) => (
            <SwiperSlide key={provider.id}>
              <div
                className="h-[200px] overflow-hidden rounded-lg bg-cover bg-center bg-no-repeat shadow-md"
                style={{
                  backgroundImage: `url('${provider.background}')`,
                }}
              >
                <div className="absolute bottom-0 right-10">
                  <Image
                    alt={`${provider.title}model`}
                    draggable={false}
                    height={200}
                    src={provider.hero}
                    unoptimized
                    width={180}
                  />
                </div>
                <div className="relative h-full w-full justify-between p-4">
                  <Image
                    alt={`${provider.title}logo`}
                    draggable={false}
                    height={50}
                    src={provider.logo}
                    unoptimized
                    width={125}
                  />
                  <Button
                    className="absolute bottom-4 left-4 rounded-lg border"
                    color={ButtonColors.Transparent}
                    onClick={() => {
                      onOpenProvider(provider);
                    }}
                    size={SizeTypes.Small}
                  >
                    {t('bet-now')}
                  </Button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SportProviderContainer;
